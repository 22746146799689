import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
    <p>I'm making this by following the Gatsby Tutorial.</p>
    <StaticImage
      alt="Schubert, a black and white border collie, giving us a roguish stare."
      src="../images/Schubi.png"
    />
  </Layout>
  )
}

export default IndexPage